/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/
(function () {
    var tablists = document.querySelectorAll('[role="tablist"]');
    var tabs;
    var panels;


    // For easy reference
    var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46,
        enter: 13,
        space: 32
    };

    function generateArrays() {
        tabs = document.querySelectorAll('[role="tab"]');
        panels = document.querySelectorAll('[role="tabpanel"]');
    };

    generateArrays();

    // Add or subtract depending on key pressed
    var direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };

    // Bind listeners
    for (var i = 0; i < tabs.length; ++i) {
        addListeners(i);
    };

    function addListeners(index) {
        tabs[index].addEventListener('click', clickEventListener);
        tabs[index].addEventListener('keydown', keydownEventListener);
        tabs[index].addEventListener('keyup', keyupEventListener);

        // Build an array with all tabs (<button>s) in it
        tabs[index].index = index;
    };

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(event) {
        var tab = event.target;
        activateTab(tab, false);
    };

    // Handle keydown on tabs
    function keydownEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.end:
                event.preventDefault();
                // Activate last tab
                focusLastTab(event);
                break;
            case keys.home:
                event.preventDefault();
                // Activate first tab
                focusFirstTab(event);
                break;

            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case keys.up:
            case keys.down:
                determineOrientation(event);
                break;
        };
    };

    // Handle keyup on tabs
    function keyupEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.left:
            case keys.right:
                determineOrientation(event);
                break;
            case keys.enter:
            case keys.space:
                activateTab(event.target);
                break;
        };
    };

    // When a tablistâ€™s aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation(event) {

        var key = event.keyCode;
        var vertical = event.target.closest('[role="tablist"]').getAttribute('aria-orientation') == 'vertical';
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                event.preventDefault();
                proceed = true;
            };
        }
        else {
            if (key === keys.left || key === keys.right) {
                proceed = true;
            };
        };

        if (proceed) {
            switchTabOnArrowPress(event);
        };
    };

    // Either focus the next, previous, first, or last tab
    // depending on key pressed
    function switchTabOnArrowPress(event) {
        var pressed = event.keyCode;

        if (direction[pressed]) {
            var target = event.target;
            if (target.index !== undefined) {
                if (tabs[target.index + direction[pressed]]) {
                    tabs[target.index + direction[pressed]].focus();
                }
                else if (pressed === keys.left || pressed === keys.up) {
                    focusLastTab(event);
                }
                else if (pressed === keys.right || pressed == keys.down) {
                    focusFirstTab(event);
                };
            };
        };
    };

    // Activates any given tab panel
    function activateTab(tab, setFocus) {

        var tabList = tab.closest('[role="tablist"]');

        setFocus = setFocus || true;
        // Deactivate all other tabs
        deactivateTabs(tabList);

        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');

        // Set focus when required
        if (setFocus) {
            tab.focus();
        };
            
        if (tab.closest(".tabs__list.tabs__list--mobile-dropdown")) {
            tab.blur();
            var previousElementSibling = tab.closest(".tabs__list.tabs__list--mobile-dropdown").previousElementSibling;
            previousElementSibling.querySelector(".tab-name").textContent = tab.textContent;
            previousElementSibling.focus();
            previousElementSibling.click();
        }

        // If sticky
        if(tab.classList.contains('is-sticky')) {
            const section = tab.closest('[data-sticky-tabs-section]');
            const header = document.querySelector('header');
            const headerHeight = header?.clientHeight;
            const scrollXPos = section.getBoundingClientRect().top + window.scrollY - (headerHeight + 10);

            setTimeout(() => {
                window.scrollTo({
                    top: scrollXPos,
                    left: 0,
                    behavior: 'smooth'
                });               
            }, 100);
        }

        try {
            $.fn.matchHeight._update();
        } catch (error) {
            console.error(error);
        }
    };

    // Deactivate all tabs and tab panels
    function deactivateTabs(tabList) {

        var currentTabList = tabList;

        for (var t = 0; t < tabs.length; t++) {

            if (currentTabList == tabs[t].closest('[role="tablist"]')) {
                tabs[t].setAttribute('tabindex', '-1');
                tabs[t].setAttribute('aria-selected', 'false');
                panels[t].setAttribute('hidden', 'hidden');
            }
        };


    };

    // Make a guess
    function focusFirstTab(event) {

        var tabList = event.target.closest('[role="tablist"]');

        for (var t = 0; t < tabs.length; t++) {
            if (tabList === tabs[t].closest('[role="tablist"]')) {
                tabs[t].focus();
                return;
            }
        };

    };

    // Make a guess
    function focusLastTab(event) {
        var tabList = event.target.closest('[role="tablist"]');
        const tabsInTabList = tabList.querySelectorAll('[role="tab"]');
        tabsInTabList[tabsInTabList.length -1].focus();
    };

    const allTabsSectionsWithMobileLayout = document.querySelectorAll(".tabs.mobile-dropdown");

    function determineMobileTabOrientation() {
        

        if (window.matchMedia("(min-width: 640px)").matches) {
            
            document.querySelectorAll('.tabs.mobile-dropdown [role="tablist"]').forEach(function (el) {
                el.setAttribute("aria-orientation", "horizontal");
                el.classList.remove("simple-dropdown");
            });

        } else {

            document.querySelectorAll('.tabs.mobile-dropdown [role="tablist"]').forEach(function (el) {
                el.setAttribute("aria-orientation", "vertical");
                el.classList.add("simple-dropdown");
            });
            
        }
    }

    function determineAddResizeEvent() {
        if (allTabsSectionsWithMobileLayout.length === 0) {
            return;
        } else {
            document.querySelectorAll(".tab-dropdown").forEach(function(el) {
                el.classList.add("js-simple-dropdown");
            });
            window.addEventListener("resize", determineMobileTabOrientation);
            determineMobileTabOrientation();
        }
    }

    function checkHashAndSetActiveTab() {
        try {
            const hash = window.location.hash;

            if (hash !== "") {
                const tab = document.querySelector(hash);
                if (tab !== null && tab !== undefined) {
                    tab.click();
                }
            }

        } catch (e) {
            console.log(e);
        }
    }
    determineAddResizeEvent();
    checkHashAndSetActiveTab();
}());